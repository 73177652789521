<template>
    <div v-if="blocked" :class="[customClass, 'wrapper-container']" @click="handleClick" :style="wrapperStyles" v-bind="$attrs"
        v-on="$listeners">
        <div style="pointer-events: none;">
            <slot></slot>
        </div>
    </div>
    <div v-else :class="customClass">
        <slot></slot>
    </div>
</template>

<script>
import {mapActions} from "vuex";
export default {
    props:{
        blocked: {
            type: Boolean,
            default: false
        },
        bodyText: {
            type: String,
            default: ''
        },
        customClass: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            wrapperStyles: {
                position: 'relative',
                pointerEvents: 'none'
            },
        };
    },
    methods: {
        ...mapActions(['closeDialog','openDialog']),
        handleClick(){
            this.openDialog({bodyText: this.bodyText})
        }
    },
    mounted() {
        this.$el.style.pointerEvents = 'all';
    },
};
</script>

<style lang="scss" scoped>
.wrapper-container{
    cursor:  pointer ;
    width: fit-content;
}
</style>