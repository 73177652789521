<template>
    <v-container v-if="question" class="pb-12">    
        <v-card outlined class="pt-4 px-4 pb-8">
            <div class="d-flex">
                <span class="text-subtitle-2 grey--text mr-2">Question</span>
                <v-chip small color="primary">{{ question.type }}</v-chip>
                <v-spacer></v-spacer>

            <upgrade-wrapper v-if="showDownload" :blocked="!enableAudioExport">
                <v-btn 
                    :color="enableAudioExport?'grey darken-2':'deep-purple accent-3'"
                    text
                    :disabled="downloadingAudio" 
                    @click.prevent="downloadAudio" elevation="0" 
                    small 
                    :loading="downloadingAudio"
                    
                >
                    <v-icon left>{{enableAudioExport?'mdi-download': 'mdi-diamond'}}</v-icon>Download audio
                </v-btn>
            </upgrade-wrapper>
            <div class="mr-4"></div>
            <v-menu v-if="question && question.type !== 'file-upload'" offset-y >
                <template v-slot:activator="{ on, attrs }">
                    <upgrade-wrapper :blocked="!enableExports">
                        <v-btn
                            :color="enableExports?'grey darken-2':'deep-purple accent-3'"
                            text
                            elevation="0"
                            v-bind="attrs"
                            v-on="on"
                            small
                        >
                            <v-icon v-if="!enableExports" left>mdi-diamond</v-icon>
                            Export
                            <v-icon right>mdi-menu-down</v-icon>
                        </v-btn>
                    </upgrade-wrapper>
                </template>
                <v-list>
                    <v-list-item :disabled="!enableExports" @click.prevent="exportData('xlsx')">
                        <v-list-item-title>XLSX (Excel)</v-list-item-title>
                    </v-list-item>
                    <v-list-item :disabled="!enableExports" @click.prevent="exportData('csv')">
                        <v-list-item-title>CSV</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            </div>
            <div class="mt-6 d-flex justify-space-between">
                <h6 class="text-h6">{{ question.text }}</h6>
                <h6 class="text-h6">{{ question.answer_count}} answers</h6>
            </div>
        </v-card>

        <top-keywords-filter v-if="isAudioTypeQuestion" class="mt-8"></top-keywords-filter>
        <v-row v-if="question.answer_count && showFrequency" class="mt-6">
            <v-col sm="6" md="4">
                <frequency-chart />
            </v-col>
        </v-row>
        <average-sentiment class="mt-8" v-if="isAudioTypeQuestion"></average-sentiment>
        <div v-if="question.answer_count && isAudioTypeQuestion" class="d-flex mt-4 mb-n4 align-center">
            <div class="d-flex align-center">
                <span class="text-subtitle-1 mr-2">Answers</span>
                <span class="text-body-1 text--grey-darken-1">{{appliedFilterText}}</span>
            </div>
            <v-spacer></v-spacer>
            <div class="sentiment-filter-menu mr-4">
                <upgrade-wrapper :blocked="!enableSentiment">
                    <v-select
                        v-model="sentimentFilter"
                        :items="sentimentFilterItems"
                        item-text="text"
                        item-value="value"
                        label="By sentiment"
                        hide-details
                        outlined
                        dense
                        @change="resetAudioPlayback"
                        :disabled="!enableSentiment"
                    >
                    <template slot="prepend-inner">
                        <v-icon small color="deep-purple accent-3" class="pt-1 pb-2 px-2" v-if="!enableSentiment">mdi-diamond</v-icon>
                    </template>
                </v-select>
                </upgrade-wrapper>
            </div>
        </div>
        <div v-if="showActiveFilters" class="d-flex mt-4 mb-n4 align-center">
            <div class="text-body-2 grey--text text--darken-2 mr-4">Active filters: </div>
            <v-chip small outlined color="grey darken-1" class="mr-4" v-if="$route.query && $route.query.keywords" close @click:close="selectedKeywords = []">Themes</v-chip>
            <v-chip small outlined color="grey darken-1" class="mr-4" v-if="sentimentFilter" close @click:close="sentimentFilter = null">Sentiment</v-chip>
        </div>

        <div class="mt-4">

            <v-card v-if="isValidating" color="primary lighten-5" flat class="d-flex pa-4 my-6 align-center justify-center">
                <v-progress-circular
                    indeterminate
                    size="64"
                    color="primary">
                </v-progress-circular>
            </v-card>
            
            <template>
                <audio-list-items v-if="isDisplayAudioItems" class="mt-8" :answers="answersFetch.data" />
                <regular-list-items v-else-if="hasAnswers" :answers="answersFetch.data" />
                <div v-if="showLoadMore" class="d-flex pa-6 full-width flex-grow-1 align-center justify-center" v-intersect="{
                    handler: onIntersect,
                    options: {
                        threshold: 0,
                        rootMargin: '0px 0px 400px 0px',
                    }
                }">
                    <v-btn :loading="loadingMore" :disabled="loadingMore" elevation="0" @click="loadMore">Load More Answers</v-btn>
                </div>
                <no-answer v-if="!hasAnswers"></no-answer>
            </template>
        </div>

        <v-footer v-if="isAudioTypeQuestion && audioOnPlayer" color="primary lighten-5" app padless>
            <audio-player></audio-player>
        </v-footer>
    </v-container>
</template>
<script>
import ContainerCard from "@/components/dashboard/ContainerCard.vue";
import PercetageBar from '@/components/chart/PercentageBar.vue';
import { mapActions, mapGetters, mapMutations } from "vuex";
import axios from 'axios'
import {YESNO, RATING, CHECKBOX, MULTIPLE_CHOICE, DROPDOWN, VOICE_RESPONSE, VOICE_QUESTION, NET_PROMOTER_SCORE} from '../../../../configs/questionTypes'
import ListItem from '../../../../components/answer/ListItem.vue';
import AudioListItems from '../../../../components/answer/audio/AudioListItems.vue';
import RegularListItems from "../../../../components/answer/regular/RegularListItems.vue";
import TopKeywordsFilter from '../../../../components/keywords/TopKeywordsFilter.vue';
import FrequencyChart from './FrequencyChart.vue';
import NoAnswer from './NoAnswers.vue'
import AverageSentiment from './AverageSentiment.vue';
import { exportAudio } from '../../../../api/survey';
import AudioPlayer from '../../../../components/AudioPlayer.vue';
import {ref} from 'vue'
import useSWRV from 'swrv';
import { useRoute } from 'vue-router/composables';
import fetcher from '@/api/fetcher';
import useSwrvState from '@/utils/useSwrvState'
import UpgradeWrapper from '../../../../components/UpgradeWrapper.vue';

export default {
    name: "QuestionDetails",
    components: { AudioPlayer, ContainerCard, PercetageBar, ListItem, TopKeywordsFilter, NoAnswer, AverageSentiment, AudioListItems, RegularListItems, FrequencyChart, UpgradeWrapper },
    props: ['question'],
    data() {
        return {
            expandAllItems: false,
            openedPanels: [],
            sentimentFilterItems: [
                {text: 'All', value: null},
                {text: 'Positive', value: 'positive'},
                {text: 'Negative', value: 'negative'},
                {text: 'Neutral', value: 'neutral'}
            ],
            downloadingAudio: false,
            loadingMore: false,
            loadedPageNumber: 1,
        };
    },
    watch: {
        '$route.params.questionId': {
            handler: function () {
                this.loadedPageNumber = 1;
            },
        },
        '$route.query': {
            handler: function () {
                this.loadedPageNumber = 1;
                this.resetAudioPlayback()
            },
        },
        // load more during player next click. preloads before the last item.
        'audioPlayer.currentAudioAnswer': function (currentAudioAnswer) {
            if(currentAudioAnswer && this.showLoadMore && this.audioPlayer.currentAudioAnswer.id === this.answersFetch.data[this.answersFetch.data.length - 2].id){
                this.loadMore();
            }
        }
    },
    computed: {
        ...mapGetters({ 
            audioPlayer: 'survey/audioPlayer',
            features: 'auth/features',
        }),
        sentimentFilter: {
            get() {
                return this.$route.query.sentiment || null;
            },
            set(value) {
                this.loadedPageNumber = 1;
                this.$router.push({ query: { ...this.$route.query, sentiment: value } });
            }
        },
        audioOnPlayer() {
            return this.audioPlayer.currentAudioAnswer !== null;
        },
        enableAudioAnalytics(){
            return !!this.features && this.features.audio_analytics
        },
        enableAudioExport(){
            return !!this.features && this.features.audio_export
        },
        enableExports(){
            return !!this.features && this.features.data_export
        },
        questionId(){
            return this.$route.params.questionId
        },
        answers() {
            let answers = [];
            if (this.question) {
                answers = this.question.answers;
            }
            return answers;
        },
        showDownload() {
            if(this.isAudioTypeQuestion && this.answersFetch && this.answersFetch.data && this.answersFetch.data.length){
                return !!this.answersFetch.data.find(answer => !!answer.file_path);
            }
            return false
        },
        isAudioTypeQuestion(){
            return [VOICE_QUESTION, VOICE_RESPONSE].includes(this.question.type)
        },
        getFrequenciesEntries(){
            return Object.entries(this.getFrequencies())
        },
        
        showFrequency(){
            return this.question && [YESNO, RATING, CHECKBOX, MULTIPLE_CHOICE, DROPDOWN, NET_PROMOTER_SCORE].includes(this.question.type)
        },

        completion() {
            let completion = {
                completionRate: 0,
                dropouts: 0,
            };
            if (this.answers) {
                const views = this.question.views_count;
                const starts = this.question.starts_count
                if(views === 0 && starts === 0){
                    completion.completionRate = 0
                    completion.dropouts = 0
                    return completion
                }
                if(starts > views){
                    completion.completionRate = 100
                }else{
                    completion.completionRate = views && starts && Number((starts/views*100).toFixed(2)) || 0
                }
            }
            completion.dropouts = Number((100 - completion.completionRate).toFixed(2));
            return completion;
        },
        appliedFilterText(){
            if(this.answersFetch){
                return `${this.answersFetch.total} of ${this.question.answer_count} answers visible`
            }
            return ''
        },

        showActiveFilters(){
            return this.question.answer_count && this.isAudioTypeQuestion && (this.selectedKeywords.length || this.sentimentFilter)
        },
        showLoadMore(){
            return ![this.STATES.PENDING].includes(this.state) && this.answersFetch.data && this.answersFetch.data.length && this.answersFetch.data.length < this.answersFetch.total
        },
        selectedKeywords: {
            get() {
                return this.$route.query.keywords ? this.$route.query.keywords.split(',') : []
            },
            set(value) {
                this.loadedPageNumber = 1;
                this.$router.push({ query: { ...this.$route.query, keywords: value.join(',') } });
            }
        },
        enableSentiment(){
            return !!this.features && this.features.audio_analytics
        },
        hasAnswers() {
            return this.answersFetch && this.answersFetch.data && this.answersFetch.data.length;
        },
        isDisplayAudioItems() {
            return this.isAudioTypeQuestion && this.hasAnswers;
        },
    },
    methods: {
        ...mapActions({
            setSurvey: "survey/setSurvey",
        }),
        ...mapMutations({
            showSnackBar: 'showSnackBar',
            resetAudioPlayback: 'survey/resetAudioPlayback',
        }),

        async exportData(type) {
            let response = await axios.get(
                `/api/question/${this.$route.params.questionId}/export?type=${type}`,
                {
                    responseType: "blob",
                }
            );
            const contentType = response.headers["content-type"];
            const contentDisposition = response.headers["content-disposition"];
            const data = response.data;
            const fileName = contentDisposition.substring(
                contentDisposition.indexOf("filename=") + "filename=".length
            );
            const fileUrl = window.URL.createObjectURL(
                new Blob([data], { type: contentType })
            );
            const fileLink = document.createElement("a");
            fileLink.href = fileUrl;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
        },
        onIntersect (entries) {
            if(entries[0].isIntersecting){
                this.loadMore()
            }
        },

        async downloadAudio() {
            try {
                this.downloadingAudio=true
                await exportAudio(this.questionId, 'question')
                this.downloadingAudio=false
            } catch (error) {
                this.downloadingAudio=false

                this.showSnackBar({
                    text: 'Cannot download audio.', 
                    color: 'error', 
                    timeout: 2000,
                })
            }
        },
        async loadMore() {
            try {
                if (this.answersFetch && this.answersFetch.data && this.answersFetch.data.length) {
                    this.loadingMore = true
                    this.loadedPageNumber += 1

                    const queryParams = new URLSearchParams();
                    queryParams.set("page", this.loadedPageNumber);
                    queryParams.set("limit", this.limit);
                    if(this.$route.query.sentiment){
                        queryParams.set("sentiment", this.$route.query.sentiment);
                    }

                    if(this.$route.query.keywords){
                        queryParams.set("keywords", this.$route.query.keywords);
                    }
                    // Fetch the next page
                    const nextPageData = await fetcher(`/api/analytics/${this.$route.params.surveyId}/questions/${this.$route.params.questionId}/answers?${queryParams.toString()}`)
                    if (nextPageData && nextPageData.data && nextPageData.data.length) {
                        // Update the cache with the next page's data
                        this.answersFetch.data = [...this.answersFetch.data, ...nextPageData.data]
                    }
                    this.loadingMore = false
                }
            } catch (error) {
                this.loadingMore = false
                this.loadedPageNumber-=1
                this.showSnackBar({
                    text: 'Cannot load more answers. Please try again later.', 
                    color: 'error', 
                    timeout: 2000,
                })

            }
        },
  
    },
    setup() {
        const route = useRoute();
        const limit = ref(10)
        // caching only first page
        const { data, error, isValidating, mutate } = useSWRV(
            () => {
                const queryParams = new URLSearchParams();
                queryParams.set("page", 1);
                queryParams.set("limit", limit.value);
                if(route.query.sentiment){
                    queryParams.set("sentiment", route.query.sentiment);
                }

                if(route.query.keywords){
                    queryParams.set("keywords", route.query.keywords);
                }

                return `/api/analytics/${route.params.surveyId}/questions/${route.params.questionId}/answers?${queryParams.toString()}`;
            },
            fetcher,
            {
                revalidateOnFocus: false,
            }
        )
        const { state, STATES } = useSwrvState(data, error, isValidating)

        return {
            state, STATES,
            answersFetch: data,
            error,
            isValidating,
            revalidateAnswers: mutate,
            limit
        }
    },
};
</script>
<style lang="scss" scoped>
.sentiment-filter-menu{
    max-width: 140px;
}
</style>
